<script>
import { mapGetters } from 'vuex';
import i18n from './i18n';
import ComposerPage from './containers/ComposerPage.vue';
import EndPage from './containers/EndPage.vue';
import Wrapper from 'ne01-common/src/components/Wrapper';
import ErrorMessage from './components/ErrorMessage.vue';
import LoadIndicator from './components/LoadIndicator.vue';

export default {
  name: 'app',
  components: {
    Wrapper,
    ErrorMessage,
    LoadIndicator,
    ComposerPage,
    EndPage
  },
  mounted() {
    this.initApp();
  },
  methods: {
    initApp() {
      if(!window.apiUrl) {
        window.apiUrl = 'https://lasauge.bird-song.ch';
      }
      if(!window.cmsUrl) {
        window.cmsUrl = 'https://wp.ls.bird-song.ch';
      }
      if(!window.cmsSlug) {
        window.cmsSlug = 'station-6';
      }

      const visitorId = new URL(window.location.href).searchParams.get('visitor') || 1;
      const lang = new URL(window.location.href).searchParams.get('lang') || 'de';

      i18n.locale = lang;

      this.$store.dispatch('initApp', { visitorId, lang });
      this.$store.dispatch('loadCmsData', lang);
      this.$store.dispatch('loadVisitorData', visitorId);
      this.$store.dispatch('startStation');
    },
    exitStation() {
      this.$store.commit('setViewBlocked', true);
      this.$store.dispatch('exitStation');
    }
  },
  computed: {
    ...mapGetters({
      lang: 'getLang',
      loading: 'getCmsDataLoading',
      error: 'getCmsDataError',
      heading: 'getStationHeading',
      intro: 'getStationIntro',
      screenState: 'getStationScrrenState',
      introTrack: 'getStationIntroTrack'
    })
  }
}
</script>

<template>
  <div id="app">
    <wrapper v-if="!loading && !error" v-bind:src="introTrack" v-bind:heading="heading" nr="6" v-on:exit="exitStation">
      <div class="station-content">
        <composer-page v-if="screenState === 'composer'"></composer-page>
        <end-page v-if="screenState === 'end'"></end-page>
      </div>
      <p slot="intro">{{intro}}</p>
    </wrapper>
 
    <div class="load-indicator" v-if="loading">
      <load-indicator></load-indicator>
    </div>

    <error-message v-bind:error="error" v-on:reloadData="initApp()"></error-message>

  </div>
</template>

<style lang="scss">
@import './assets/styles/variables';
@import './assets/styles/common';

#app {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  font-family: var(--font-family-secondary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark, black);
  background: var(--color-mainm, #CCC);
}

.station-content {
  position: absolute;
  box-sizing: border-box;
  top: 0; left: 0; width: 100%; height: 100%;
  min-height: 100%;
  padding-top: calc(var(--gutter) * 5);
  overflow-y: auto;
}

.load-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

</style>
